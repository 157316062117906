import * as d3 from 'd3';

import * as geometry from './geometry';
import * as utils from './utils';
import { Legend } from './legend';
import { Dropdowns } from './dropdowns';
import { BottomBar } from '../bottom-bar';
import { randomColor } from './color';
import {
  handleMouseOverCountry,
  handleMouseOverYear,
  handleMouseOutYear,
} from './desktop';
import { removeAnnotations, renderAnnotations } from './annotations';
import { showCountryRankings } from './graph';

const data = require('../../data/eurovision.json');

const LABEL_FACTOR = 1.03;
export const ENTRANCE_ANIMATION_DELAY = utils.calculateBlockEntranceDelay(
  data.length - 1,
  data[data.length - 1].countries.length,
  data[data.length - 1].countries.length - 1,
);

export class Graph {
  constructor() {
    const _this = this;

    this._selectedCountry = null;
    this._selectedYear = null;
    this._data = data;

    this.bottomBar = new BottomBar(
      (x) => {
        // On select country
        this._selectedCountry = x;
        this._selectedYear = null;
        this.dropdowns.selectCountry(x);
        this.dropdowns.clearYear();
        this.showZoomedCountryRankings(x);
        this.bottomBar.showCountryHistory(x);
        _this.zoomOut();
      },
      // On select Year
      (x) => {
        this._selectedYear = x;
        this.handleTouchYear(x);
        this.dropdowns.selectYear(x);
        this.bottomBar.showYearParticipants(x);
      },
    );

    this.dropdowns = new Dropdowns(
      // On select country
      (x) => {
        _this._selectedCountry = x;
        _this.showZoomedCountryRankings(x);
        _this.bottomBar.showCountryHistory(x);
      },
      // On clear country
      () => {
        _this._selectedCountry = null;
        _this.clearCountrySelection();
        if (this._selectedYear) {
          _this.bottomBar.showYearParticipants(this._selectedYear);
        } else {
          _this.bottomBar.reset();
        }
      },
      // On select Year
      (x) => {
        _this._selectedYear = x;
        _this.handleTouchYear(x);
        _this.bottomBar.showYearParticipants(x);
      },
      // On clear year
      () => {
        _this._selectedYear = null;
        if (_this._selectedCountry) {
          _this.showZoomedCountryRankings(this._selectedCountry);
          _this.bottomBar.showCountryHistory(this._selectedCountry);
        } else {
          _this.bottomBar.reset();
        }

        removeAnnotations();
        _this.zoomOut();
      },
    );

    this.calculateMetrics();
    this.renderInitialAnimatedGraph(this.legend);

    this.legend = new Legend(
      ENTRANCE_ANIMATION_DELAY,
      () => _this.resetGraph(),
      (year) => _this.handleMouseClickYear(year),
    );
  }

  calculateMetrics() {
    this._isLargeScreen = window.matchMedia('(min-width: 75em)').matches;
    this._isMediumScreen =
      !this._isLargeScreen && window.matchMedia('(min-width: 40em)').matches;

    this.margin = this._isLargeScreen
      ? {
          left: 200,
          right: 200,
          top: 100,
          bottom: 100,
        }
      : this._isMediumScreen
      ? { left: 50, right: 50, top: 50, bottom: 50 }
      : {
          left: 25,
          right: 25,
          top: 25,
          bottom: 25,
        };

    const {
      offsetHeight: containerHeight,
      offsetWidth: containerWidth,
    } = document.getElementById('graph');

    const boundedDimension = d3.min([
      containerWidth - this.margin.left - this.margin.right,
      containerHeight - this.margin.top - this.margin.bottom,
    ]);

    if (!this._isLargeScreen) {
      const svgWidth = boundedDimension + this.margin.left + this.margin.right;

      if (svgWidth < containerWidth) {
        this.margin.left += (containerWidth - svgWidth) / 2;
        this.margin.right += (containerWidth - svgWidth) / 2;
      }
    }

    this.dimensions = {
      boundedWidth: boundedDimension,
      boundedHeight: boundedDimension,
      height: boundedDimension + this.margin.top + this.margin.bottom,
      width: boundedDimension + this.margin.right + this.margin.left,
    };

    this.radius = boundedDimension / 2;
    // Adding one for the space between the first and last year
    this.angleSlice = geometry.angleDelta(this._data.length + 1);
    this.angleShift = this.angleSlice / 2;
    this.rScale = d3.scaleLinear().domain([0, 1]).range([0, this.radius]);
    this.getX = (value, index) =>
      geometry.getX(
        this.rScale(value),
        (index + 1) * this.angleSlice - Math.PI / 2,
      );
    this.getY = (value, index) =>
      geometry.getY(
        this.rScale(value),
        (index + 1) * this.angleSlice - Math.PI / 2,
      );
    this.arc = d3.arc();
  }

  resizeGraph() {
    this.calculateMetrics();
    this._isLargeScreen = window.matchMedia('(min-width: 75em)').matches;

    const wrapper = d3
      .select('#graph')
      .select('svg')
      .attr('width', this.dimensions.width)
      .attr('height', this.dimensions.height)
      .select('#wrapper')
      .style(
        'transform',
        `translate(${this.radius + this.margin.left}px, ${
          this.radius + this.margin.top
        }px)`,
      );

    wrapper.select('#base-circle').attr('r', this.radius);

    const yearGroups = wrapper.selectAll('.year-group');

    yearGroups
      .select('.year-label')
      .attr('text-anchor', (d, i) =>
        this._isLargeScreen && i >= this._data.length / 2 ? 'end' : 'start',
      )
      .style('transform', (d, i) => {
        const flipLabelRotation =
          this._isLargeScreen && i >= this._data.length / 2 ? Math.PI : 0;
        return `translate(${this.getX(LABEL_FACTOR, i)}px, ${this.getY(
          LABEL_FACTOR,
          i,
        )}px)rotate(${
          (i + 1) * this.angleSlice - Math.PI / 2 + flipLabelRotation
        }rad)`;
      });

    yearGroups.each((year, index) => {
      const startAngle = index * this.angleSlice + this.angleShift;
      const endAngle = (index + 1) * this.angleSlice + this.angleShift;

      const depth = this.radius / year.countries.length;
      const ranks = [
        ...new Set(year.countries.map((x) => x.unifiedranking)),
      ].sort((a, b) => b - a);
      const countriesWithMeta = year.countries.map((country, i) => {
        return {
          ...country,
          innerRadius: i * depth,
          outerRadius: (i + 1) * depth,
          color: country.colors?.length
            ? randomColor(country.colors)
            : '#DEDEDE',
          rank: ranks.indexOf(country.unifiedranking) + 1,
        };
      });
      this._data[index].countries = countriesWithMeta;

      yearGroups
        .filter((d) => d.year === year.year)
        .selectAll('path')
        .attr('d', (_, index) =>
          this.arc({
            innerRadius: countriesWithMeta[index].innerRadius,
            outerRadius: countriesWithMeta[index].outerRadius,
            startAngle,
            endAngle,
          }),
        );
    });
  }

  selectCountry(country) {
    this._selectedCountry = country;
    this.legend.buildCountryHistory(country);
    showCountryRankings(country);
    d3.selectAll('.year-group').style('opacity', 1);
  }

  resetGraph() {
    d3.select('#header').classed('semi-transparent', false);
    const _this = this;
    this._selectedCountry = null;
    this._selectedYear = null;
    this.dropdowns.clearYear();
    this.dropdowns.clearCountry();
    this.bottomBar.reset();
    this.legend.resetLegend();
    removeAnnotations();
    this.zoomOut();

    const yearGroups = d3.selectAll('.year-group');
    yearGroups.style('pointer-events', 'auto');
    yearGroups.transition().style('opacity', 1);

    yearGroups
      .classed('highlighted-year', false)
      .select('text')
      .style('opacity', 1)
      .transition()
      .style('font-size', '0.75em');

    yearGroups.selectAll('g').style('display', 'inline').style('opacity', 1);

    yearGroups
      .on(
        'mouseover',
        (_, year) =>
          this._isLargeScreen && handleMouseOverYear(year, this._data),
      )
      .on('mouseout', () => this._isLargeScreen && handleMouseOutYear());

    yearGroups.each((year, index) => {
      const startAngle = index * this.angleSlice + this.angleShift;
      const endAngle = (index + 1) * this.angleSlice + this.angleShift;
      const countriesWithMeta = this._data.find((x) => x.year === year.year)
        .countries;

      yearGroups
        .filter((d) => d.year === year.year)
        .selectAll('.country-block')
        .on(
          'mouseover',
          (_, d) =>
            this._isLargeScreen &&
            handleMouseOverCountry(
              countriesWithMeta.find((x) => x.country === d.country),
              startAngle,
              endAngle,
              this.radius,
            ),
        )
        .on('mouseout', () => {
          if (this._isLargeScreen) {
            if (!_this._selectedCountry && !_this._selectedYear) {
              d3.select('#header').classed('semi-transparent', false);
            }

            removeAnnotations();
          }
        })
        .on('click', (event, d) => {
          event.preventDefault();
          event.stopPropagation();
          d3.selectAll('.year-group')
            .style('opacity', 1)
            .classed('highlighted-year', false);

          if (this._isLargeScreen) {
            _this._selectedYear = null;
            _this.selectCountry(d.country);
            showCountryRankings(d.country);
          } else {
            _this.dropdowns.clearCountry();
            _this._selectedYear = year.year;
            _this._selectedCountry = d.country;
            _this.handleTouchYear(year.year);
            _this.handleTouchCountry(d.country, year.year);
            _this.bottomBar.showYearParticipants(year.year);
          }
        });
    });
  }

  handleMouseClickYear(year) {
    const _this = this;
    this.resetGraph();
    this._selectedYear = year;
    d3.select('#header').classed('semi-transparent', true);

    const yearGroups = d3.selectAll('.year-group');
    yearGroups
      .filter((d) => d.year !== year)
      .selectAll('.country-block')
      .on('mouseover', null)
      .on('mouseout', null)
      .on('click', () => _this.resetGraph());

    yearGroups.transition().style('opacity', 1);

    yearGroups.select('text').style('opacity', 1);

    yearGroups
      .classed('highlighted-year', false)
      .transition()
      .style('opacity', 0.25);
    yearGroups
      .filter((d) => d.year === year)
      .classed('highlighted-year', true)
      .transition()
      .style('opacity', 1);

    yearGroups.on('mouseover', null).on('mouseout', null);

    // add on click on the low opacity portion to bring back main circle
    this.legend.buildYearParticipants(year);
  }

  renderInitialAnimatedGraph() {
    const _this = this;

    const wrapper = d3
      .select('#graph')
      .append('svg')
      .attr('width', this.dimensions.width)
      .attr('height', this.dimensions.height)
      .append('g')
      .attr('id', 'wrapper')
      .style(
        'transform',
        `translate(${this.radius + this.margin.left}px, ${
          this.radius + this.margin.top
        }px)`,
      );

    // Disable graph interaction while animation is going
    d3.select('#graph')
      .select('svg')
      .style('pointer-events', 'none')
      .transition()
      .delay(ENTRANCE_ANIMATION_DELAY + 1)
      .style('pointer-events', 'auto');

    // Data Driven content
    // const extraContainer = wrapper.append('g').attr('class', 'extra-container');
    const content = wrapper.append('g').attr('id', 'content');

    content
      .append('circle')
      .attr('id', 'base-circle')
      .attr('r', this.radius)
      .attr('cx', 0)
      .attr('cy', 0)
      .attr('fill', '#07092e')
      .on('click', (event) => {
        _this.resetGraph();
        event.stopPropagation();
        event.preventDefault();
      })
      .on('touchstart', () => {
        this._staticTouch = true;
      })
      .on('touchmove', () => {
        this._staticTouch = false;
      })
      .on('touchend', (event) => {
        if (this._staticTouch) {
          _this.resetGraph();
          event.stopPropagation();
          event.preventDefault();
          this._staticTouch = false;
        }
      })
      .attr('opacity', 0)
      .transition()
      .duration(0)
      .delay(ENTRANCE_ANIMATION_DELAY)
      .attr('opacity', 1);

    const yearGroups = content
      .selectAll('g')
      .data(this._data)
      .join('g')
      .attr('class', (d) => `year-group year-${d.year}`)
      .on(
        'mouseover',
        (_, year) =>
          this._isLargeScreen && handleMouseOverYear(year, this._data),
      )
      .on('mouseout', () => this._isLargeScreen && handleMouseOutYear())
      .on('touchend', (_, d) => {
        _this.handleTouchYear(d.year);
        _this.bottomBar.showYearParticipants(d.year);
      })
      .on('click', (_, d) => {
        if (this._isLargeScreen) {
          _this.handleMouseClickYear(d.year, _this.resetGraph);
        } else {
          _this.handleTouchYear(d.year);
          _this.bottomBar.showYearParticipants(d.year);
        }
      });

    yearGroups
      .append('text')
      .attr('y', 0)
      .attr('x', 0)
      .attr('fill', 'white')
      .attr('class', 'year-label')
      .attr('text-anchor', (d, i) =>
        this._isLargeScreen && i >= this._data.length / 2 ? 'end' : 'start',
      )
      .attr('dominant-baseline', 'central')
      .style('font-size', '0.75em')
      .style('transform', (d, i) => {
        const flipLabelRotation =
          this._isLargeScreen && i >= this._data.length / 2 ? Math.PI : 0;
        return `translate(${this.getX(LABEL_FACTOR, i)}px, ${this.getY(
          LABEL_FACTOR,
          i,
        )}px)rotate(${
          (i + 1) * this.angleSlice - Math.PI / 2 + flipLabelRotation
        }rad)`;
      })
      .text((d) => d.year)
      .style('cursor', 'pointer')
      .style('opacity', 0)
      .transition()
      .duration(0)
      .delay((_, i) => i * 45)
      .style('opacity', 1);

    yearGroups.each((year, index) => {
      const startAngle = index * this.angleSlice + this.angleShift;
      const endAngle = (index + 1) * this.angleSlice + this.angleShift;

      const depth = this.radius / year.countries.length;
      const ranks = [
        ...new Set(year.countries.map((x) => x.unifiedranking)),
      ].sort((a, b) => b - a);
      const countriesWithMeta = year.countries.map((country, i) => {
        return {
          ...country,
          innerRadius: i * depth,
          outerRadius: (i + 1) * depth,
          labeled: i > year.countries.length - 5,
          color: country.colors?.length
            ? randomColor(country.colors)
            : '#DEDEDE',
          rank: ranks.indexOf(country.unifiedranking) + 1,
        };
      });
      // Updating main data object to include meta data
      this._data[index].countries = countriesWithMeta;

      // COUNTRIES
      const countryGroup = yearGroups
        .filter((d) => d.year === year.year)
        .selectAll('g')
        .data(countriesWithMeta)
        .join('g')
        .attr('class', (d) => `country-block ${d.country}`)
        .on('mouseover', (_, d) => {
          this._isLargeScreen &&
            handleMouseOverCountry(
              countriesWithMeta.find((x) => x.country === d.country),
              startAngle,
              endAngle,
              this.radius,
            );
        })
        .on('mouseout', () => {
          if (this._isLargeScreen) {
            if (!_this._selectedCountry && !_this._selectedYear) {
              d3.select('#header').classed('semi-transparent', false);
            }
            removeAnnotations();
          }
        })
        .style('cursor', 'pointer')
        .on('click', (event, d) => {
          event.preventDefault();
          event.stopPropagation();
          d3.selectAll('.year-group')
            .style('opacity', 1)
            .classed('highlighted-year', false);

          if (this._isLargeScreen) {
            _this._selectedYear = null;
            _this.selectCountry(d.country);
            showCountryRankings(d.country);
          } else {
            _this.dropdowns.clearCountry();
            _this._selectedYear = year.year;
            _this._selectedCountry = d.country;
            _this.handleTouchYear(year.year);
            _this.handleTouchCountry(d.country, year.year);
            _this.bottomBar.showYearParticipants(year.year);
          }
        })
        .on('touchend', (event, d) => {
          event.preventDefault();
          event.stopPropagation();
          this.dropdowns.clearCountry();
          _this._selectedYear = year.year;
          _this._selectedCountry = d.country;
          _this.handleTouchYear(year.year);
          _this.handleTouchCountry(d.country, year.year);
          _this.bottomBar.showYearParticipants(year.year);
        });

      countryGroup
        .append('path')
        .attr('fill', (d) => d.color)
        .attr('d', (d) =>
          this.arc({
            innerRadius: d.innerRadius,
            outerRadius: d.outerRadius,
            startAngle,
            endAngle,
          }),
        )
        .attr('fill-opacity', 0)
        .transition()
        .duration(0)
        .delay(function (d, i) {
          return utils.calculateBlockEntranceDelay(
            index,
            year.countries.length,
            i,
          );
        })
        .attr('fill-opacity', 1);
    });
  }

  handleTouchYear(year) {
    this._selectedYear = year;
    const index = this._data.findIndex((d) => d.year === year);
    this.dropdowns.selectYear(year);

    removeAnnotations();
    // Adding an annotation if a country is selected
    if (this._selectedCountry) {
      const countryData = this._data[index].countries.find(
        (country) => country.country === this._selectedCountry,
      );

      if (countryData) {
        this.addFixedAnnotation(countryData);
      }
    }

    d3.select('#content')
      .transition()
      .style(
        'transform',
        `rotate(${
          2 * Math.PI - index * this.angleSlice - this.angleShift * 2
        }rad) scale(1.75)`,
      );

    d3.selectAll('.year-group')
      .style('opacity', 1)
      .filter((d) => d.year !== year)
      .style('opacity', 0.2);

    d3.select('#wrapper')
      .transition()
      .style(
        'transform',
        `translate(${this.radius + this.margin.left}px, ${
          this.radius * 2 + this.margin.top + this.margin.bottom
        }px)`,
      );
  }

  handleTouchCountry(country, year) {
    this.selectedCountry = country;
    this.dropdowns.selectCountry(country);
    removeAnnotations();

    const annotationData = this._data
      .find((x) => x.year === year)
      .countries.find((x) => x.country === country);
    this.addFixedAnnotation(annotationData);
  }

  showZoomedCountryRankings(country) {
    removeAnnotations();
    showCountryRankings(country);

    // disable other years
    d3.selectAll('.year-group')
      .filter((d) => !d.countries.some((x) => x.country === country))
      .style('pointer-events', 'none');

    if (this._selectedYear) {
      const countryYearData = this._data
        .find((x) => x.year === this._selectedYear)
        .countries.find((x) => x.country === country);

      this.addFixedAnnotation(countryYearData);
    }
  }

  addFixedAnnotation(d) {
    const _this = this;

    const normalY = -d.outerRadius + (d.outerRadius - d.innerRadius) / 2;

    const annotation = [
      {
        note: {
          label: d.artist,
          title: d.country,
        },
        x: 0,
        y: normalY * 1.75, // Multiply because graph is scaled up
        dx: 40,
        dy: normalY > -50 ? (-50 - normalY) * 1.75 : 0,
      },
    ];
    renderAnnotations(annotation, d.rank);

    d3.select('.annotation-note-title')
      .style('text-decoration', 'underline')
      .style('font-size', '1.1em')
      .style('cursor', 'pointer')
      .on('touchend', (event) => {
        event.stopPropagation();
        event.preventDefault();
        removeAnnotations();

        _this._selectedYear = null;
        _this.dropdowns.clearYear();

        _this.showZoomedCountryRankings(this._selectedCountry);
        _this.bottomBar.showCountryHistory(this._selectedCountry);

        _this.zoomOut();
      })
      .on('click', (event) => {
        event.stopPropagation();
        event.preventDefault();
        removeAnnotations();

        _this._selectedYear = null;
        _this.dropdowns.clearYear();

        _this.showZoomedCountryRankings(this._selectedCountry);
        _this.bottomBar.showCountryHistory(this._selectedCountry);

        _this.zoomOut();
      });
  }

  zoomOut() {
    d3.select('#content').transition().style('transform', `rotate(0)`);
    d3.selectAll('.year-group').style('opacity', 1);
    d3.select('#wrapper')
      .transition()
      .style(
        'transform',
        `translate(${this.radius + this.margin.left}px, ${
          this.radius + this.margin.top
        }px)`,
      );
  }

  clearCountrySelection() {
    removeAnnotations();

    const yearGroups = d3.selectAll('.year-group');
    yearGroups.style('pointer-events', 'auto');
    yearGroups.selectAll('text').style('opacity', 1);

    yearGroups
      .selectAll('g')
      .style('display', 'inline')
      .transition()
      .style('opacity', 1);
  }
}
