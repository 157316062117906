function average(numbers) {
  return numbers.reduce((a, b) => a + b) / numbers.length;
}

/**
 * Returns a random color from an array of colors
 *
 * @param {string[]} colors hex colors to choose from without the preceding #
 * @returns {string} randomly selected hex color
 */
export function randomColor(colors) {
  const filteredColors = colors.filter((color) => color !== '000000');
  return `#${
    filteredColors[Math.floor(Math.random() * filteredColors.length)]
  }`;
}

/**
 * Returns the blended color for an array of colors
 *
 * @param {string[]} colors hex colors to blend without the preceding #
 * @returns {string} blended hex color
 */
export function blendColors(colors) {
  if (!colors.length) return 'transparent';

  const colorsR = [],
    colorsB = [],
    colorsG = [];

  colors.forEach((color) => {
    const [colorR, colorB, colorG] = `#${color}`
      .match(/\w\w/g)
      .map((c) => parseInt(c, 16));

    colorsR.push(colorR);
    colorsG.push(colorB);
    colorsB.push(colorG);
  });

  const r = Math.round(average(colorsR)).toString(16).padStart(2, '0');
  const g = Math.round(average(colorsG)).toString(16).padStart(2, '0');
  const b = Math.round(average(colorsB)).toString(16).padStart(2, '0');

  return '#' + r + g + b;
}
