import * as d3 from 'd3';

const data = require('../../../data/eurovision.json');

import { scrolledToBottom, getCountryHistory } from './utils';
import { getCountryEntryYear } from '../utils';
import { showCountryRankings } from '../graph';

const SCROLL_INCREMENT = 200;

export class Legend {
  constructor(contentTransitionDuration, resetGraph, handleClickYear) {
    this.scrolledToBottom = false;
    this._data = data.map((x) => {
      const ranks = [...new Set(x.countries.map((x) => x.unifiedranking))].sort(
        (a, b) => b - a,
      );

      return {
        ...x,
        countries: x.countries
          .map((country) => ({
            ...country,
            rank: ranks.indexOf(country.unifiedranking) + 1,
          }))
          .reverse(),
      };
    });

    this.container = d3
      .select('#desktop-legend')
      .append('div')
      .style('position', 'relative');

    // Initial render
    this.buildLegend(contentTransitionDuration, resetGraph);

    this.resetLegend = () => this.buildLegend(0, 0);
    this._resetGraph = resetGraph;
    this._handleClickYear = handleClickYear;
  }

  onScroll(event) {
    const atBottom = scrolledToBottom(event.target);
    if (this.scrolledToBottom !== atBottom) {
      this.scrolledToBottom = atBottom;

      d3.select('#scroll-icon')
        .transition()
        .style('opacity', atBottom ? 0 : 1);
    }
  }

  selectCountry(country) {
    this.buildCountryHistory(country);
    showCountryRankings(country);

    d3.selectAll('.year-group')
      .style('opacity', 1)
      .classed('highlighted-year', false);
  }

  addBackButton() {
    const _this = this;
    this.container
      .append('button')
      .attr('id', 'back-button')
      .text('Back')
      .style('margin-bottom', '1em')
      .on('click', () => {
        _this._resetGraph();
        _this.resetLegend();
      });
  }

  buildLegend(transitionDuration) {
    // Checking if the legend has already been built
    if (document.getElementById('country-entry')) {
      return;
    }

    const _this = this;

    const titleTransitionDuration = transitionDuration ? 1000 : 0;
    const contentTransitionDuration = transitionDuration;

    this.container
      .style('pointer-events', 'none')
      .transition()
      .delay(contentTransitionDuration)
      .style('pointer-events', 'auto');

    this.clearLegend();

    const countryEntryWithMeta = [];
    this._data.forEach((year) => {
      year.countries.forEach((country) => {
        if (
          !countryEntryWithMeta
            .map((x) => x.country)
            .includes(country.country) &&
          country.participates2020
        ) {
          countryEntryWithMeta.push({ ...country, year: year });
        }
      });
    });

    const title = this.container
      .append('h2')
      .text('Year countries\njoined the contest')
      .style('white-space', 'pre-wrap')
      .style('margin-bottom', '5px');

    title
      .style('opacity', 0)
      .transition()
      .duration(titleTransitionDuration)
      .style('opacity', 1);

    const gradientContainer = this.container
      .append('div')
      .attr('class', 'overflow-gradient')
      .style('position', 'relative');

    const legendGroups = gradientContainer
      .append('ul')
      .attr('id', `country-entry`)
      .style('height', '80%')
      .selectAll('#legend-year')
      .data(countryEntryWithMeta)
      .join('li')
      .style('font-size', '0.75em')
      .style('position', 'relative');

    const countryListElement = document.getElementById('country-entry');

    if (countryListElement && scrolledToBottom(countryListElement)) {
      this.container
        .append('button')
        .on('click', () => {
          countryListElement.scroll({
            top: countryListElement.scrollTop + SCROLL_INCREMENT,
            behavior: 'smooth',
          });
        })
        .append('img')
        .attr('id', 'scroll-icon')
        .attr('src', require('../../../assets/arrow.svg'))
        .style('margin-left', '3em')
        .style('margin-right', '3em')
        .style('margin-bottom', '1em')
        .style('width', 20)
        .style('height', 20)
        .style('opacity', 0)
        .transition()
        .delay(contentTransitionDuration)
        .duration('.2s')
        .style('opacity', 1);

      countryListElement.addEventListener('scroll', this.onScroll);
    }

    legendGroups
      .filter(
        (d, index) =>
          index === 0 ||
          countryEntryWithMeta[index].year !==
            countryEntryWithMeta[index - 1].year,
      )
      .style('margin-top', '.5em')
      .append('button')
      .attr('class', (d) => `legend-${d.year.year}`)
      .style('position', 'absolute')
      .text((d) => d.year.year)
      .on('click', (_, d) => _this._handleClickYear(d.year.year));

    const legendCountryGroups = legendGroups
      .append('button')
      .attr('class', (d) => `legend-country legend-${d.alpha2}`)
      .style('margin-left', '2.5em')
      .style('position', 'relative')
      .on('click', (_, d) => _this.selectCountry(d.country));

    legendCountryGroups
      .append('img')
      .attr('src', (d) => `/flags/${d.alpha2}.png`)
      .attr('width', 16);

    legendCountryGroups
      .append('span')
      .style('margin-left', '0.25em')
      .text((d) => d.country);

    if (contentTransitionDuration) {
      gradientContainer
        .style('max-height', 0)
        .transition()
        .delay(250)
        .duration(contentTransitionDuration)
        .ease(d3.easeLinear)
        .style('max-height', '100%');
    }
  }

  buildYearParticipants(year) {
    const yearData = this._data.find((x) => x.year === year);

    // Checking if the ranking for the selected year are already being displayed
    if (document.getElementById(`${yearData.year}-ranking`)) {
      return;
    }
    this.clearLegend();
    const _this = this;

    this.addBackButton();
    this.container
      .append('h2')
      .style('white-space', 'pre-wrap')
      .style('margin-bottom', '0.25em')
      .text(`${yearData.year} Rankings`);

    const noteContainer = this.container
      .append('div')
      .style('margin-top', '0.85em');

    noteContainer
      .append('img')
      .attr('src', require('../../../assets/star.svg'))
      .attr('width', '12');

    noteContainer
      .append('span')
      .text('Joined this year')
      .style('font-size', '0.75em')
      .style('margin-left', '0.25em');

    const gradientContainer = this.container
      .append('div')
      .attr('class', 'overflow-gradient')
      .style('position', 'relative');

    const legendGroups = gradientContainer
      .append('ul')
      .attr('id', `${yearData.year}-ranking`)
      .style('height', '80%')
      .selectAll('#legend-ranking')
      .data(yearData.countries)
      .join('li')
      .style('margin-top', '0.4em')
      .style('font-size', '0.75em')
      .style('position', 'relative')
      .style('display', 'flex')
      .style('align-items', 'flex-start');

    legendGroups
      .filter(
        (d, index) =>
          index === 0 ||
          yearData.countries[index].rank !== yearData.countries[index - 1].rank,
      )
      .style('margin-top', '0.5em')
      .append('span')
      .style('text-align', 'right')
      .style('position', 'absolute')
      .text((d) => `#${d.rank}`);

    const legendCountryGroups = legendGroups
      .append('button')
      .attr('class', (d) => `bold legend-country legend-${d.country}`)
      .style('margin-left', '2em')
      .on('click', (_, d) => _this.selectCountry(d.country));

    legendCountryGroups
      .append('img')
      .attr('src', (d) => `/flags/${d.alpha2}.png`)
      .attr('width', 16);

    legendCountryGroups
      .append('span')
      .style('margin-left', '0.3em')
      .text((d) => `${d.country}`)
      .filter((d) => {
        return getCountryEntryYear(d.country) === yearData.year;
      })
      .append('img')
      .attr('src', require('../../../assets/star.svg'))
      .attr('width', '12')
      .style('margin-bottom', '-0.1em')
      .style('margin-left', '0.25em');

    legendGroups
      .append('span')
      .style('margin-left', '0.5em')
      .style('flex', 1)
      .text((d) => `${d.artist}`);

    const listElement = document.getElementById(`${yearData.year}-ranking`);

    if (listElement && !scrolledToBottom(listElement)) {
      this.container
        .append('button')
        .style('width', '100%')
        .on('click', () => {
          listElement.scroll({
            top: listElement.scrollTop + SCROLL_INCREMENT,
            behavior: 'smooth',
          });
        })
        .append('img')
        .attr('id', 'scroll-icon')
        .attr('src', require('../../../assets/arrow.svg'))
        .style('display', 'block')
        .style('margin', 'auto')
        .style('margin-bottom', '1em')
        .style('width', 20)
        .style('height', 20)
        .style('opacity', 0)
        .transition()
        .duration('.2s')
        .style('opacity', 1);
      listElement.addEventListener('scroll', this.onScroll);
    }
  }

  buildCountryHistory(country) {
    // Checking if the ranking for the selected year are already being displayed
    if (document.getElementById(`${country}-history`)) {
      return;
    }
    this.clearLegend();
    const _this = this;

    const countryHistoryData = getCountryHistory(country, data);

    this.addBackButton();
    this.container
      .append('h2')
      .style('white-space', 'pre-wrap')
      .style('margin-bottom', '0.25em')
      .text(`${country} over the years`);

    // TODO: change to a table
    const header = this.container
      .append('h4')
      .style('display', 'flex')
      .style('margin-bottom', 0);
    header.append('span').style('width', '3rem').text('Year');
    header.append('span').style('flex', '1').text('Artist');
    header.append('span').text('Rank');

    const gradientContainer = this.container
      .append('div')
      .attr('class', 'overflow-gradient')
      .style('position', 'relative');

    const legendGroups = gradientContainer
      .append('ul')
      .attr('id', `${country}-history`)
      .style('height', '80%')
      .selectAll(`.legend-country-history`)
      .data(countryHistoryData)
      .join('li')
      .style('margin-top', '0.5em')
      .style('font-size', '0.75em')
      .style('display', 'flex')
      .style('align-items', 'flex-start')
      .style('position', 'relative');

    legendGroups
      .append('button')
      .style('width', '3rem')
      .text((d) => d.year)
      .on('click', (_, d) => _this._handleClickYear(d.year));

    legendGroups
      .append('span')
      .style('flex', 1)
      .style('margin-right', '0.4em')
      .text((d) => `${d.country.artist}`);

    legendGroups
      .append('span')
      .style('text-align', 'right')
      .text((d) => `#${d.country.rank}`);

    const listElement = document.getElementById(`${country}-history`);

    if (listElement && !scrolledToBottom(listElement)) {
      this.container
        .append('button')
        .style('width', '100%')
        .on('click', () => {
          listElement.scroll({
            top: listElement.scrollTop + SCROLL_INCREMENT,
            behavior: 'smooth',
          });
        })
        .append('img')
        .attr('id', 'scroll-icon')
        .attr('src', require('../../../assets/arrow.svg'))
        .style('display', 'block')
        .style('margin', 'auto')
        .style('margin-bottom', '1em')
        .style('width', 20)
        .style('height', 20)
        .style('opacity', 0)
        .transition()
        .duration('.2s')
        .style('opacity', 1);
      listElement.addEventListener('scroll', this.onScroll);
    }
  }

  clearLegend() {
    this.container.selectAll('*').remove();
  }
}
