import * as d3 from 'd3';

export function showCountryRankings(country) {
  const yearGroups = d3.selectAll('.year-group');

  yearGroups.selectAll('g').style('display', 'inline');
  yearGroups.on('mouseover', null).on('mouseout', null);

  const activeYears = yearGroups.filter((d) =>
    d.countries.some((d) => d.country === country),
  );

  activeYears.select('text').transition().style('opacity', 1);

  activeYears
    .selectAll('g')
    .filter((d) => d.country === country)
    .transition()
    .style('opacity', 1);

  yearGroups
    .filter((d) => !d.countries.some((d) => d.country === country))
    .select('text')
    .transition()
    .style('opacity', 0.2);

  yearGroups
    .selectAll('g')
    .filter((d) => d.country !== country)
    .style('opacity', 0)
    .style('display', 'none');
}
