import * as d3 from 'd3';

import * as geometry from './geometry';
import { renderAnnotations } from './annotations';

export function handleMouseOverYear(year, data) {
  const yearGroups = d3.selectAll('.year-group');

  const hoveredIndex = data.findIndex((d) => d.year === year.year);
  yearGroups.style('opacity', (d, index) => {
    return index === hoveredIndex ? 1 : 0.5;
  });
}

export function handleMouseOutYear() {
  const yearGroups = d3.selectAll('.year-group');

  yearGroups.style('opacity', 1);
}

export function handleMouseOverCountry(data, startAngle, endAngle, radius) {
  const angle = (startAngle + endAngle) / 2 - Math.PI / 2,
    hypotenuse = data.outerRadius;

  const x = geometry.getX(hypotenuse, angle);
  const annotation = [
    {
      note: {
        label: data.artist,
        title: data.country,
        align: 'left',
      },
      x,
      y: geometry.getY(hypotenuse, angle),
      dx: x > 0 ? radius + 75 - x : -radius - x - 150,
      dy: 0,
    },
  ];

  renderAnnotations(annotation, data.rank);
}
