export function scrolledToBottom(element) {
  if (!element) {
    return;
  }

  const { scrollHeight, offsetHeight, scrollTop } = element;
  return scrollHeight - offsetHeight <= scrollTop + 5;
}

export function getCountryHistory(country, data) {
  const countryYears = [];

  data.forEach((year) => {
    const countryEntryInfo = year.countries.find(
      (countryEntry) => countryEntry.country === country,
    );
    if (countryEntryInfo)
      countryYears.push({ year: year.year, country: countryEntryInfo });
  });

  return countryYears;
}
