import * as d3 from 'd3';
import { getCountries } from './utils';
import { Dropdown } from './dropdown';

const data = require('../../../data/eurovision.json');

export class Dropdowns {
  constructor(
    handleSelectCountry,
    handleClearCountry,
    handleSelectYear,
    handleClearYear,
  ) {
    this._allCountryData = getCountries(data);
    this._allYearData = data.map((year) => ({ value: year.year })).reverse();

    this.countryDropdown = new Dropdown(
      'country-dropdown',
      'Country',
      this._allCountryData,
      this.populateCountries,
      (country) => {
        handleSelectCountry(country);
        this.yearDropdown.resetOptions(
          data
            .filter((year) => year.countries.find((x) => x.country === country))
            .map((x) => ({ value: x.year }))
            .reverse(),
        );
      },
      () => {
        handleClearCountry();
        this.yearDropdown.resetOptions(this._allYearData);
      },
    );
    this.selectCountry = (country) => {
      this.countryDropdown.select(country);
      this.yearDropdown.resetOptions(
        data
          .filter((year) => year.countries.find((x) => x.country === country))
          .map((x) => ({ value: x.year }))
          .reverse(),
      );
    };
    this.clearCountry = () => {
      this.countryDropdown.clearValue();
      this.yearDropdown.resetOptions(this._allYearData);
    };

    this.yearDropdown = new Dropdown(
      'year-dropdown',
      'Year',
      this._allYearData,
      this.populateYears,
      (year) => {
        handleSelectYear(year);
        this.countryDropdown.resetOptions(getCountries(data, year));
      },
      () => {
        handleClearYear();
        this.countryDropdown.resetOptions(this._allCountryData);
      },
    );
    this.selectYear = (year) => {
      this.yearDropdown.select(year);
      this.countryDropdown.resetOptions(getCountries(data, year));
    };
    this.clearYear = () => {
      this.yearDropdown.clearValue();
      this.countryDropdown.resetOptions(this._allCountryData);
    };
  }

  populateCountries(optionsClassname) {
    const groups = d3
      .selectAll(`.${optionsClassname}`)
      .style('display', 'flex')
      .style('width', '100%')
      .style('margin-bottom', '0.2em');

    groups
      .append('img')
      .attr('src', (d) => `/flags/${d.alpha2}.png`)
      .attr('width', 16)
      .style('display', 'inline-block')
      .style('margin-right', '0.5em')
      .style('margin-top', '0.25em');

    groups
      .append('span')
      .text((d) => d.value)
      .style('white-space', 'nowrap')
      .style('text-align', 'left');
  }

  populateYears(optionsClassname) {
    d3.selectAll(`.${optionsClassname}`)
      .style('margin-bottom', '0.2em')
      .append('span')
      .text((d) => d.value);
  }
}
