import * as d3 from 'd3';

/**
 * Returns the X value when given an angle and the normalized value (hypotenuse)
 *
 * @param {number} r normalized value
 * @param {number} theta angle from origin
 * @returns {number} get x coordinate
 */
export function getX(r, theta) {
  return r * Math.cos(theta);
}

/**
 * Returns the Y value when given an angle and the normalized value (hypotenuse)
 *
 * @param {number} r normalized value
 * @param {number} theta angle from origin
 * @returns {number} get y coordinate
 */
export function getY(r, theta) {
  return r * Math.sin(theta);
}

/**
 * Returns the angle in radians between a given number of equidistant axis
 *
 * @param {number} numAxes number of axes
 * @returns {number} angle in radians
 */
export function angleDelta(numAxes) {
  return (Math.PI * 2) / numAxes;
}

export function arcTweenStartAngle(arc, newAngle) {
  return function (d) {
    var interpolate = d3.interpolate(d.startAngle, newAngle);
    return function (t) {
      d.startAngle = interpolate(t);
      return arc(d);
    };
  };
}
