import * as d3 from 'd3';

export class Dropdown {
  constructor(id, placeholder, data, populateOptions, onSelect, onClear) {
    this._container = d3.select(`#${id}`).attr('class', 'dropdown-container');
    this._baseId = id;
    this._placeholder = placeholder;
    this._expanded = false;
    this._populateOptions = populateOptions;
    this._data = data;
    this._onSelect = onSelect;
    this._staticTouch = false;

    const button = this._container
      .append('button')
      .attr('class', 'dropdown-button')
      .attr('id', `${id}-button`)
      .style('display', 'flex')
      .style('align-items', 'center')
      .on('touchend', () => {
        if (this._selectedValue) {
          if (onClear) onClear();
          this.clearValue();
        } else {
          d3.select(`#${id}-content`).classed(
            'dropdown-content-visible',
            this._expanded ? false : true,
          );

          d3.select('#dropdown-group').classed(
            'expanded-dropdown',
            this._expanded ? false : true,
          );

          this._expanded = !this._expanded;
        }
      })
      .on('click', () => {
        if (this._selectedValue) {
          if (onClear) onClear();
          this.clearValue();
        } else {
          d3.select(`#${id}-content`).classed(
            'dropdown-content-visible',
            this._expanded ? false : true,
          );

          d3.select('#dropdown-group').classed(
            'expanded-dropdown',
            this._expanded ? false : true,
          );

          this._expanded = !this._expanded;
        }
      });

    button.append('span').attr('id', `${id}-button-label`).text(placeholder);

    button
      .append('img')
      .attr('id', `${id}-button-icon`)
      .attr('src', require('../../../../assets/arrow.svg'))
      .style('display', 'inline-block')
      .style('margin-top', '0.2em')
      .style('margin-left', '0.5em')
      .style('width', 12)
      .style('height', 12);

    this._container
      .append('div')
      .attr('class', 'dropdown-content')
      .attr('id', `${id}-content`)
      .append('ul')
      .selectAll(`li`)
      .data(data)
      .join('li')
      .append('button')
      .attr('class', `${id}-option`)
      .attr('id', (d) => `value-${d.value}`)
      .on('touchstart', () => {
        this._staticTouch = true;
      })
      .on('touchmove', () => {
        this._staticTouch = false;
      })
      .on('touchend', (e, d) => {
        if (this._staticTouch) {
          if (onSelect) onSelect(d.value);
          this.select(d.value);
          this._staticTouch = false;

          d3.select('#dropdown-group').classed('expanded-dropdown', false);
        }

        e.preventDefault();
      })
      .on('click', (e, d) => {
        if (onSelect) onSelect(d.value);
        this.select(d.value);

        d3.select('#dropdown-group').classed('expanded-dropdown', false);

        e.preventDefault();
      });

    populateOptions(`${id}-option`);

    document.addEventListener('click', (e) => {
      const dropdown = document.getElementById(id);
      const isInside = dropdown.contains(e.target);

      if (!isInside) {
        if (this._expanded) {
          d3.select(`#${id}-content`).classed(
            'dropdown-content-visible',
            false,
          );
          this._expanded = false;
          d3.select('#dropdown-group').classed('expanded-dropdown', false);
        }
      } else {
        e.preventDefault();
      }
    });
    document.addEventListener('touchend', (e) => {
      const dropdown = document.getElementById(id);
      const isInside = dropdown.contains(e.target);

      if (!isInside) {
        if (this._expanded) {
          d3.select(`#${id}-content`).classed(
            'dropdown-content-visible',
            false,
          );
          d3.select('#dropdown-group').classed('expanded-dropdown', false);

          this._expanded = false;
        }
      } else {
        e.preventDefault();
      }
    });
  }

  select(value) {
    d3.select(`#${this._baseId}-button-icon`)
      .attr('src', require('../../../../assets/remove.svg'))
      .style('width', 12)
      .style('height', 10);

    d3.select(`#${this._baseId}-button-label`).text(value);
    this._selectedValue = value;

    d3.select(`#${this._baseId}-content`).classed(
      'dropdown-content-visible',
      false,
    );

    d3.select('#dropdown-group').classed('expanded-dropdown', false);

    this._expanded = false;
  }

  clearValue() {
    this._selectedValue = null;
    d3.select(`#${this._baseId}-button-label`).text(this._placeholder);
    d3.select(`#${this._baseId}-button-icon`)
      .attr('src', require('../../../../assets/arrow.svg'))
      .style('height', 12);
  }

  resetOptions(data) {
    const _this = this;
    this._data = data;
    d3.select(`#${this._baseId}-content`).selectAll('ul').remove();

    d3.select(`#${this._baseId}-content`)
      .append('ul')
      .selectAll(`li`)
      .data(data)
      .join('li')
      .append('button')
      .attr('class', `${this._baseId}-option`)
      .attr('id', (d) => `value-${d.value}`)
      .on('touchstart', () => {
        this._staticTouch = true;
      })
      .on('touchmove', () => {
        this._staticTouch = false;
      })
      .on('touchend', (e, d) => {
        if (this._staticTouch) {
          if (_this._onSelect) _this._onSelect(d.value);
          this.select(d.value);

          d3.select('#dropdown-group').classed('expanded-dropdown', false);
        }

        e.preventDefault();
      })
      .on('click', (e, d) => {
        if (_this._onSelect) _this._onSelect(d.value);
        this.select(d.value);

        d3.select('#dropdown-group').classed('expanded-dropdown', false);

        e.preventDefault();
      });

    this._populateOptions(`${this._baseId}-option`);
  }
}
