import * as d3annotations from 'd3-svg-annotation';
import * as d3 from 'd3';

export function removeAnnotations() {
  d3.selectAll('.annotation-group').remove();
}

/**
 * Renders annotations using d3-annotations
 *
 * @param {Object[]} annotations see https://d3-annotation.susielu.com/#api
 * @param {number} rank rank of the annotation NOTE: needs updating since this only allows for one rank
 */
export function renderAnnotations(annotations, rank) {
  const makeAnnotations = d3annotations
    .annotation()
    .type(d3annotations.annotationCallout)
    .annotations(annotations);
  const annotationGroup = d3
    .select('#wrapper')
    .append('g')
    .attr('class', 'annotation-group')
    .call(makeAnnotations);

  annotationGroup
    .select('g g g g .annotation-note .annotation-note-content')
    .attr('transform', 'translate(0, 9)')
    .attr('font-size', '13px')
    .select('.annotation-note-label')
    .attr('transform', 'translate(0, -4)');

  annotationGroup
    .select('g g g g .annotation-note')
    .append('text')
    .attr('fill', 'white')
    .attr('dy', '-0.7em')
    .style('font-size', '13px')
    .style('font-weight', 'bold')
    .text(`#${rank}`);

  d3.select('#header').classed('semi-transparent', true);
}
