import * as d3 from 'd3';
import { getCountryEntryYear } from '../graph/utils';
import { getCountryHistory } from '../graph/legend/utils';

const data = require('../../data/eurovision.json');

export class BottomBar {
  constructor(onSelectCountry, onSelectYear) {
    this._expanded = false;

    this._data = data.map((x) => {
      const ranks = [...new Set(x.countries.map((x) => x.unifiedranking))].sort(
        (a, b) => b - a,
      );

      return {
        ...x,
        countries: x.countries
          .map((country) => ({
            ...country,
            rank: ranks.indexOf(country.unifiedranking) + 1,
          }))
          .reverse(),
      };
    });

    this._container = d3.select('#bottom-bar');

    this._container
      .append('span')
      .style('font-size', '0.9em')
      .style('text-align', 'center')
      .text('Tap the circle to explore');
    this._onSelectCountry = onSelectCountry;
    this._onSelectYear = onSelectYear;
  }

  reset() {
    this._expanded = false;
    this._container.selectAll('*').remove();
    this._container
      .append('span')
      .style('font-size', '0.9em')
      .style('text-align', 'center')
      .text('Tap the circle to explore');
  }

  setLabel(label) {
    this._expanded = false;
    this._container.select('span').style('font-size', '0.9em').text(label);
  }

  toggleExpansion() {
    d3.select('#bottom-bar-button-icon')
      .transition()
      .style('transform', `rotate(${this._expanded ? 180 : 0}deg)`);
    d3.select('#bottom-bar-expandable-container').classed(
      'content-expanded',
      this._expanded ? false : true,
    );
    this._expanded = !this._expanded;
  }

  setupExpandableContainer(label = 'click me') {
    this._container.selectAll('*').remove();

    const container = this._container
      .append('div')
      .attr('id', 'bottom-bar-expandable-container');

    const button = container
      .append('button')
      .attr('id', 'bottom-bar-button')
      .on('touchend', (e) => {
        this.toggleExpansion();
        e.preventDefault();
      })
      .on('click', (e) => {
        this.toggleExpansion();
        e.preventDefault();
      });

    button
      .append('span')
      .style('font-size', '1.2em')
      .style('font-weight', 'bold')
      .text(label);
    button
      .append('img')
      .attr('id', `bottom-bar-button-icon`)
      .attr('src', require('../../assets/arrow.svg'))
      .style('margin-left', '0.5em')
      .style('margin-top', '0.25em')
      .style('width', 14)
      .style('height', 14)
      .style('transform', 'rotate(180deg)');

    container.append('div').attr('id', 'bottom-bar-expandable-content');
  }

  showYearParticipants(year) {
    this.setupExpandableContainer(`Show ${year} rankings`);

    const content = d3.select('#bottom-bar-expandable-content');

    const noteContainer = content.append('div').style('margin-top', '0.85em');
    noteContainer
      .append('img')
      .attr('src', require('../../assets/star.svg'))
      .attr('width', '12');

    noteContainer
      .append('span')
      .text('Joined this year')
      .style('font-size', '0.75em')
      .style('margin-left', '0.25em');

    const yearData = this._data.find((x) => x.year === year);

    const listGroup = content
      .append('ul')
      .selectAll('.bottom-bar-year-participant')
      .data(yearData.countries)
      .join('li')
      .style('margin-top', '0.4em')
      .style('font-size', '0.75em')
      .style('position', 'relative')
      .style('display', 'flex')
      .style('align-items', 'flex-start');

    listGroup
      .filter(
        (_, index) =>
          index === 0 ||
          yearData.countries[index].rank !== yearData.countries[index - 1].rank,
      )
      .style('margin-top', '0.5em')
      .append('span')
      .style('text-align', 'right')
      .style('position', 'absolute')
      .text((d) => {
        return `#${d.rank}`;
      });

    const listCountryGroup = listGroup
      .append('button')
      .style('flex', 1.25)
      .style('margin-left', '2em')
      .on('touchstart', () => {
        this._staticTouch = true;
      })
      .on('touchmove', () => {
        this._staticTouch = false;
      })
      .on('touchend', (e, d) => {
        if (this._staticTouch) {
          if (this._onSelectCountry) this._onSelectCountry(d.country);
          this._staticTouch = false;

          this.toggleExpansion();
        }

        e.preventDefault();
      })
      .on('click', (e, d) => {
        if (this._onSelectCountry) this._onSelectCountry(d.country);
        this._staticTouch = false;

        this.toggleExpansion();

        e.preventDefault();
      });

    listCountryGroup
      .append('img')
      .style('margin-top', '0.2em')
      .attr('src', (d) => `/flags/${d.alpha2}.png`)
      .attr('width', 16);

    listCountryGroup
      .append('span')
      .style('margin-left', '0.3em')
      .style('font-weight', '600')
      .text((d) => `${d.country}`)
      .filter((d) => {
        return getCountryEntryYear(d.country) === year;
      })
      .append('img')
      .attr('src', require('../../assets/star.svg'))
      .attr('width', '12')
      .style('margin-bottom', '-0.1em')
      .style('margin-left', '0.25em');

    listGroup
      .append('span')
      .style('margin-left', '0.5em')
      .style('flex', 1)
      .text((d) => `${d.artist}`);
  }

  showCountryHistory(country) {
    const countryHistoryData = getCountryHistory(country, this._data);

    this.setupExpandableContainer(`Show ${country} over the years`);
    const content = d3.select('#bottom-bar-expandable-content');

    const header = content
      .append('h4')
      .style('display', 'flex')
      .style('margin-top', 0)
      .style('margin-bottom', 0);
    header.append('span').style('width', '3rem').text('Year');
    header.append('span').style('flex', '1').text('Artist');
    header.append('span').text('Rank');

    const listGroup = content
      .append('ul')
      .style('margin-top', 0)
      .selectAll('.bottom-bar-country-history')
      .data(countryHistoryData)
      .join('li')
      .style('margin-top', '0.5em')
      .style('font-size', '0.75em')
      .style('display', 'flex')
      .style('align-items', 'flex-start')
      .style('position', 'relative');

    // TODO: add click handler
    listGroup
      .append('button')
      .style('width', '3rem')
      .text((d) => d.year)
      .on('touchstart', () => {
        this._staticTouch = true;
      })
      .on('touchmove', () => {
        this._staticTouch = false;
      })
      .on('touchend', (e, d) => {
        if (this._staticTouch) {
          if (this._onSelectYear) this._onSelectYear(d.year);
          this._staticTouch = false;

          this.toggleExpansion();
        }

        e.preventDefault();
      })
      .on('click', (e, d) => {
        if (this._onSelectYear) this._onSelectYear(d.year);
        this._staticTouch = false;

        this.toggleExpansion();

        e.preventDefault();
      });

    listGroup
      .append('span')
      .style('flex', 1)
      .style('margin-right', '0.4em')
      .text((d) => `${d.country.artist}`);

    listGroup
      .append('span')
      .style('text-align', 'right')
      .text((d) => `#${d.country.rank}`);
  }
}
