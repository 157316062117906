export function getCountries(data, year) {
  if (year) {
    return data
      .find((x) => x.year === year)
      .countries.map((country) => ({
        alpha2: country.alpha2,
        value: country.country,
      }))
      .sort((a, b) => {
        if (a.value < b.value) {
          return -1;
        }
        if (a.value > b.value) {
          return 1;
        }
        return 0;
      });
  }

  const countryData = {};
  return [
    ...new Set(
      data
        .flatMap((year) => year.countries)
        .map((country) => {
          if (!countryData[country.country])
            countryData[country.country] = {
              alpha2: country.alpha2,
              value: country.country,
            };

          return country.country;
        }),
    ),
  ]
    .sort()
    .map((country) => countryData[country]);
}
