const data = require('../../data/eurovision.json');

export function calculateBlockEntranceDelay(
  yearIndex,
  yearCountriesLength,
  countryIndex,
) {
  return (
    countryIndex * (countryIndex / yearCountriesLength) * 25 + yearIndex * 45
  );
}

export function getCountryEntryYear(country) {
  for (const year of data) {
    if (year.countries.map((country) => country.country).includes(country)) {
      return year.year;
    }
  }
}
